@import "../mixins/flex.scss";
@import "../mixins/fonts.scss";

.sectionPrimary {
  background: var(--bg-primary);
}

.sectionSecondary {
  background: var(--bg-tertiary);
}

.sectionContainer {
  padding: 48px 32px;
  .sectionWrap {
    text-align: center;
    .title {
      @include setFontTertiaryBold(38px, var(--clr-primary));
      text-transform: uppercase;
    }
    .subtitle {
      @include setFontPrimary(16px, var(--clr-secondary));
      padding-top: 8px;
    }
  }
}

@media screen and (min-width: 991px) {
  .sectionContainer {
    padding: 68px 32px;
    .sectionWrap {
      .title {
        @include setFontSize(48px);
      }
      .subtitle {
        @include setFontSize(20px);
        max-width: 70%;
        margin: 0 auto;
      }
    }
  }
}
