@import "../mixins/fonts.scss";

.card {
  overflow: hidden;
  border-radius: 5px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  border: 1px solid var(--border);
  background-color: var(--card-primary);
  .cardHeader {
    padding: 14px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--clr-primary);
    .icon {
      width: 28px;
      height: 28px;
    }
  }
  .cardContent {
    padding: 28px;
    text-align: center;

    .header {
      @include setFontPrimarySemiBold(18px, var(--clr-primary));
      padding-bottom: 8px;
    }
    .desc {
      @include setSecondaryRegular(16px, var(--clr-secondary));
    }
  }
  .cardActions {
    padding: 0px 12px 14px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.card:not(:last-child) {
  margin-bottom: 18px;
}

@media screen and (min-width: 991px) {
  .card:not(:last-child) {
    margin-bottom: 0px;
  }
  .card {
    width: calc(100% - 20px);
    margin: 0px 10px;
  }
}
