@import "../mixins/flex.scss";
@import "../mixins/fonts.scss";

.list {
  margin-top: 28px;
}

@media screen and (min-width: 991px) {
  .list {
    display: flex;
    margin: 48px -10px;
  }
}
