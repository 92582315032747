@import "../mixins/fonts.scss";
@import "../mixins/flex.scss";

.button {
  border: none;
  outline: none;
  width: auto;
  border-radius: 5px;
  user-select: none;
  padding: 7px 12px;
}

.primary {
  background-color: var(--clr-secondary);
  @include setSecondarySemiBold(16px, var(--text-white));
  line-height: normal;
}
.secondary {
  background-color: var(--clr-secondary);
  @include setSecondarySemiBold(16px, #fff);
  line-height: normal;
}
.light {
  background-color: #fff;
  @include setSecondarySemiBold(16px, var(--clr-secondary));
  border: 1px solid var(--clr-secondary);
  line-height: normal;
}
.btnPrimary {
  background-color: var(--btn-primary);
  @include setSecondarySemiBold(16px, var(--clr-secondary));
  line-height: normal;
}
.disabled {
  background-color: var(--bg-disabled);
  @include setSecondarySemiBold(16px, #fff);
  line-height: normal;
}
.lg {
  min-height: 48px;
}
.xl {
  min-height: 64px;
}
.sm {
  min-height: 38px;
  padding: 8px;
  font-size: 14px;
  line-height: 18px;
}
.xsm {
  min-height: 28;
  padding: 4px;
  font-size: 14px;
  line-height: 18px;
}

.fullWidth {
  width: 100%;
}
.halfWidth {
  width: 48%;
}
.fixedWidth {
  width: 190px;
}
.regular {
  @include setFontPrimary(14px, var(--clr-secondary));
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
@media screen and (min-width: 768px) {
}
